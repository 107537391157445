
import Turbolinks from 'turbolinks';
import SmoothScroll from 'smooth-scroll';

(function() {
  Turbolinks.start();
  new SmoothScroll('a[href*="#"]', {
    speed: 300
  });

  document.querySelector('.-mobile-nav').addEventListener('click', function(e) {
    document.querySelector('body').classList.toggle('drawer-nav-is-open');

    e.preventDefault();
    return false;
  });
})();